svg4everybody();

// запуск библиотеки WOW
new WOW().init();

var isMobile;

// главная страница
function mainPage() {

  var preloader = $('.preloader'),
      lastPreloaderAnim = $('.preloader__list'),
      header = $('.header'),
      church = $('.church'),
      churchNav = $('.church-nav'),
      clouds = $('.preloader__clouds'),
      lastCloudsAnim = $('.preloader__cloud--7'),
      animClass = 'anim',
      activeClass = 'active',
      animCloudsClass = 'anim-clouds',
      checkClass = 'active-check',
      durationForStart = 1000,
      nav = $('.church-nav__item'),
      path = $('.church__path'),
      flag = true,
      translatePos = [
        {
          "x": "-990px",
          "y": "-200px"
        },
        {
          "x": "-790px",
          "y": "0px"
        },
        {
          "x": "-590px",
          "y": "-200px"
        },
        {
          "x": "-190px",
          "y": "-300px"
        },
        {
          "x": "-190px",
          "y": "-550px"
        },
        {
          "x": "0px",
          "y": "-500px"
        },
        {
          "x": "0px",
          "y": "0px"
        }
      ];

  if ($(window).innerWidth() < 1367) {
    translatePos = [
      {
        "x": "-1190px",
        "y": "-200px"
      },
      {
        "x": "-990px",
        "y": "0px"
      },
      {
        "x": "-790px",
        "y": "-200px"
      },
      {
        "x": "-190px",
        "y": "-300px"
      },
      {
        "x": "-190px",
        "y": "-750px"
      },
      {
        "x": "0px",
        "y": "-500px"
      },
      {
        "x": "0px",
        "y": "0px"
      }
    ];
  }
  if ($(window).innerWidth() < 1256) {
    translatePos[0].x = '-1400px'
  }
  if ($(window).innerWidth() < 1101) {
    translatePos[0].x = '-1700px';
    translatePos[1].x = '-1290px';
    translatePos[2].x = '-890px';
    translatePos[3].x = '-390px';
    translatePos[4].x = '-490px';
  }


  if (preloader.length) $(document).ready(function () {
    setTimeout(function () {
      preloader.addClass(animClass);
    }, durationForStart);
    setTimeout(function () {
      header.addClass(activeClass);
    }, durationForStart * 2);
    setTimeout(function () {
      clouds.removeClass(animCloudsClass).addClass(animClass);
    }, durationForStart * 2 + 300);
  });
  lastCloudsAnim.on('animationend', function () {
    church.addClass(activeClass);
    setTimeout(function () {
      churchNav.addClass(activeClass);
      addEvent();
    }, 1000)
  });

  function addEvent() {
    nav.click(function () {
      var index = $(this).index();
      changeSlide(index);
    });

    $(window).on('wheel', function (event) {
      if (event.originalEvent.deltaY > 0) {
        changeSlide(null, 'down');
      }
      else {
        changeSlide(null, 'up');
      }
    });

    $('body').keyup(function (e) {
      if (e.which == 40) {
        changeSlide(null, 'down');
      }
      else if (e.which == 38) {
        changeSlide(null, 'up');
      }
    });
  }

  function changeSlide(ind, dir) {
    if (flag === false) {
      return false;
    }
    flag = false;
    var index;
    if (ind !== null) {
      index = ind;
    } else {
      if (dir !== 'up') {
        index = nav.filter('.' + checkClass).index() + 1;
      } else {
        index = nav.filter('.' + checkClass).index() - 1;
      }

      if (index >= nav.length || index < 0) {
        flag = true;
        return false;
      }

    }
    var actualityPos = translatePos[index],
        cssTranslate = 'translate(' + actualityPos.x + ', ' + actualityPos.y + ')';
    church.css('transform', cssTranslate);
    nav.removeClass(activeClass);
    nav.eq(index).addClass(checkClass).siblings().removeClass(checkClass);
    for (i = 0; i < index + 1; i++) {
      nav.eq(i).addClass(activeClass);
      if (i > 0) {
        path.eq(i - 1).addClass(animClass);
      }
    }
    setTimeout(function () {
      flag = true;
    }, 300)
  }

  if ($(window).width() <= 768) {
    header.addClass(activeClass);
  }
}

// Слайдеры
function sliderInit() {
  $('.js-slider').slick({
    dots: true,
    arrows: false,
    dotsClass: 'slider__dots',
    customPaging: function () {
      return '<span></span>'
    }
  });

  $('.slider__control').click(function () {
    var $this = $(this),
        slider = $this.closest('.slider__controls').siblings('.js-slider');
    if ($this.hasClass('prev')) {
      slider.slick('slickPrev')
    } else {
      slider.slick('slickNext')
    }
  });

  $('.slider__info').click(function () {
    var block = $(this).siblings('.slider__more'),
        activeClass = 'active';

    block.toggleClass(activeClass);
  });

  $('.slider__close').click(function () {
    var block = $(this).closest('.slider__more'),
        activeClass = 'active';

    block.removeClass(activeClass);
  });
}

// скролл к элементу
function scrollNav() {
  $('.js-scroll-to').on("click", function () {
    $('html, body').stop().animate({
      scrollTop: $($(this).data('href')).offset().top - 50
    }, 400);
    return false;
  });
}

// запуск яндекс карт
function initMap(data) {
  if (data.link) {
    var htmlContentBody = '<p class="map-info__text">Адрес :' + data.address + '</p><a href="' + data.link.href + '" class="map-info__text map__link">' + data.link.text + '</a>';
  } else {
    var htmlContentBody = '<p class="map-info__text">Адрес :' + data.address + '</p>';
  }
  ymaps.ready(init);
  function init() {
    var myMap = new ymaps.Map("map", {
      center: data.center,
      zoom: data.zoom
    });

    var myPlacemark = new ymaps.Placemark(data.placemark, {
      balloonContentHeader: '<span class="map-info__subtext">Контактная информация</span>',
      balloonContentBody: htmlContentBody,
      balloonContentFooter: 'Круглосуточно',
      hintContent: data.title
    }, {
      iconLayout: 'default#image',
      iconImageHref: data.icon.src,
      iconImageSize: data.icon.size,
      iconImageOffset: data.icon.offset,
    });
    myMap.behaviors.disable(['scrollZoom'])
    myMap.geoObjects.add(myPlacemark);
  }
}

// Сурское интерактив
function surskoeInteractiv() {
  var activeClass = 'active',
      item = $('.surskoe-interactiv__item');
  close = $('.surskoe-interactiv__close');
  moreClass = 'surskoe-interactiv__more'
  item.click(function () {
    $(this).addClass(activeClass).siblings().removeClass(activeClass);
    item.find('.' + moreClass).removeClass(activeClass);
    $(this).find('.' + moreClass).addClass(activeClass);
  });
  close.click(function (e) {
    e.stopPropagation()
    $(this).closest('.' + moreClass).removeClass(activeClass);
  });
}

// Страница Термины
function glossary() {
  // Функция для липкого меню
  function stickyMenu() {
    var scroll = $(window).scrollTop();

    var topBlockHeight = $('.js-top-block').innerHeight();
    topBlockHeight += 350

    if (scroll >= topBlockHeight) {
      $('.glossary__char-list').addClass('fixed');
    } else {
      $('.glossary__char-list').removeClass('fixed');
    }
  }

  $(window).on('scroll', function () {
    stickyMenu();
  })

  $('.glossary__char-item').click(function () {
    if ($(this).hasClass('noactive')) {
      return false
    }
    $(this).addClass('active').siblings().removeClass('active');
    var index = $(this).data('index');
    if (index === 'all') {
      $('.glossary__item').show()
    }
    $('.glossary__item').filter('[data-index="' + index + '"]').show().siblings().hide();
  });
}

// Страница О проекте
function aboutPage() {
  $('.js-slider-about').slick({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  $('.slider__control').click(function () {
    var $this = $(this),
        slider = $this.closest('.slider__controls').siblings('.js-slider-about');
    if ($this.hasClass('prev')) {
      slider.slick('slickPrev')
    } else {
      slider.slick('slickNext')
    }
  });
}

// Маина интерактив
function mainaInteractiv() {
  var slider = $('.maina-interactiv__list'),
      dots = $('.maina-interactiv__dot'),
      activeClass = 'active';

  slider.slick({
    fade: true,
    arrows: false,
    dots: false,
    draggable: false,
    swipe: false,
    infinite: false,
    touchMove: false,
    accessibility: false,
    adaptiveHeight: true
  })

  dots.click(function () {
    var index = $(this).index() + 1;

    $(this).addClass(activeClass).siblings().removeClass(activeClass);

    slider.slick('slickGoTo', index, false);
  });
}

// Главное меню
function mainMenu() {
  var burger = $('.header__burger'),
      menu = $('.header__mobile'),
      activeClass = 'active';

  burger.click(function () {
    $(this).toggleClass(activeClass);
    $('body').toggleClass('overflowhidden');
    $('.footer').toggleClass('hide');
    menu.slideToggle();
  });
}

// Бсюзган интерактив
function bsyzganInteractiv() {
  var words = $('.bsyzgan-interactiv__words'),
      more = $('.bsyzgan-interactiv__more'),
      moreBlock = $('.bsyzgan-interactiv__more-block'),
      activeClass = 'active',
      windowWidth = $(window).outerWidth();

  if (windowWidth <= 768) {
    words.click(function () {
      var wordsIndex = $(this).data('index'),
          curMoreBlock = moreBlock.filter('[data-index="' + wordsIndex + '"]')
      curMoreBlock.addClass(activeClass).siblings().removeClass(activeClass);
      more.addClass(activeClass);
    });

    more.click(function (e) {
      if (e.target.classList.contains('bsyzgan-interactiv__more-block')
          || e.target.parentElement.classList.contains('bsyzgan-interactiv__more-block')) {
        return false
      }
      more.removeClass(activeClass);
      moreBlock.removeClass(activeClass);
    });
    $('body').keyup(function (e) {
      if (e.which == 27 && more.hasClass(activeClass)) {
        more.removeClass(activeClass);
        moreBlock.removeClass(activeClass);
      }
    })
  } else {
    words.hover(function () {
      var wordsIndex = $(this).data('index'),
          curMoreBlock = moreBlock.filter('[data-index="' + wordsIndex + '"]')
      curMoreBlock.addClass(activeClass).siblings().removeClass(activeClass);
    });

    $('.church-inner__interactiv').mouseleave(function () {
      moreBlock.removeClass(activeClass);
    });
  }
}

// Бсюзган интерактив 2
function bsyzganInteractiv2() {
  var items = $('.interactiv-bsyzgan__item'),
      moreBlock = $('.interactiv-bsyzgan__more'),
      images = moreBlock.find('.interactiv-bsyzgan__more-img'),
      activeClass = 'active';

  items.click(function () {
    var index = $(this).index();
    $('body').addClass('overflowhidden');
    moreBlock.addClass(activeClass);
    images.eq(index).addClass(activeClass);
  });

  moreBlock.click(function () {
    moreBlock.removeClass(activeClass);
    images.removeClass(activeClass);
    $('body').removeClass('overflowhidden');
  });
}

// Версия для слабовидящих
function changeVersion() {
  var versionLink = $('.header__version'),
      versionTextLink = versionLink.find('.header__text'),
      typeItems = $('.header__type-item');

  versionLink.click(function () {
    $('html').toggleClass('state-contrast-small');
    versionTextLink.filter('.hide').removeClass('hide').siblings().addClass('hide');
    $('.header__type').toggleClass('active');
  });
  typeItems.click(function () {
    var index = $(this).index();
    console.log(index)
    if (index == 1) {
      $('html').removeClass('mod--2').removeClass('mod--3').addClass('mod--1');
    } else if (index == 2) {
      $('html').removeClass('mod--1').removeClass('mod--3').addClass('mod--2');
    } else {
      $('html').removeClass('mod--1').removeClass('mod--2').addClass('mod--3');
    }
  });
}

// Дград интерактив
function dgradInteractiv() {
  var link = $('.interactiv__youtube-wrap'),
      block = $('.interactiv__youtube'),
      closeLink = $('.interactiv__youtube-close'),
      activeClass = 'active';

  link.click(function (e) {
    e.preventDefault();
    $('body').addClass('overflowhidden');
    $('html').css('paddingRight', '17px');
    block.addClass(activeClass);
  });
  block.on("click", function (e) {
    if (block.is(e.target) || $(e.target).hasClass("interactiv__youtube-close")) {
      block.removeClass(activeClass);
      $('body').removeClass('overflowhidden');
      $('html').removeAttr('style');
    }
  });
  closeLink.click(function () {
    block.removeClass(activeClass);
    $('body').removeClass('overflowhidden');
    $('html').removeAttr('style');
  });
  $('body').keyup(function (e) {
    if (e.which === 27) {
      block.removeClass(activeClass);
      $('body').removeClass('overflowhidden');
      $('html').removeAttr('style');
    }
  });
}

// Запуск видео на детальных стр храмов
function churchVideo(stop) {
  var video = $('.js-church__video')[0],
      $header = $('.js-header'),
      $block = $('.js-church__video-cnt'),
      $bell = $('.js-church__video-bell'), // mute
      $poster = $('.js-church__video-poster'),
      isPlay = false,
      timer = null;

  if (video) {
    isPlay = true;
    video.play();
    if (!isMobile) $header.addClass('header--hidden');
    $block.addClass('church-inner__top--hidden');
    $bell.removeClass('church-inner__bell--hidden');

    $block.on('mousemove click touchstart', function () {
      if (isPlay) {
        if (timer) clearTimeout(timer);
        if (!isMobile) $header.removeClass('header--hidden');
        $block.removeClass('church-inner__top--hidden');
        timer = setTimeout(function () {
          if (!isMobile) $header.addClass('header--hidden');
          $block.addClass('church-inner__top--hidden');
        }, 1000);
      }
    });

    $bell.on('click', function (e) {
      e.preventDefault();
      video.muted = !video.muted;
      $bell.toggleClass('church-inner__bell--muted');
      if (video.muted) $bell[0].setAttribute('title', 'Включить звук');
      else $bell[0].setAttribute('title', 'Выключить звук');
    });

    video.addEventListener('ended', function () {
      isPlay = false;
      if (timer) clearTimeout(timer);
      if (!isMobile) $header.removeClass('header--hidden');
      $block.removeClass('church-inner__top--hidden');
      $bell.addClass('church-inner__bell--hidden');
      $poster.removeClass('church-inner__poster--hidden');
    }, false);

  }

}


if ($('.interactiv__youtube-wrap').length) {
  dgradInteractiv()
}

if ($('.header__version').length) {
  changeVersion()
}

if ($('.page--index').length) {
  mainPage()
}

if ($('.js-slider').length) {
  sliderInit()
}

if ($('.js-scroll-to').length) {
  scrollNav()
}

if ($('#map').length) {
  var data = JSON.parse(document.getElementById('map').getAttribute('data-options'));
  initMap(data)
}

if ($('.page--church-surskoe').length) {
  surskoeInteractiv()
}

if ($('.page--glossary').length) {
  glossary()
}

if ($('.page--about').length) {
  aboutPage()
}

if ($('.page--church-maina').length) {
  mainaInteractiv()
}

if ($('.header').length) {
  mainMenu()
}

if ($('.page--church-bsyzgan').length) {
  bsyzganInteractiv()
  bsyzganInteractiv2()
}

if ($('.content').length) {
  $('.header').addClass('active')
}


if ($('.js-church__video').length) {
  $(document).ready(function () {
    // isMobile

    var checkMobile = function () {
      var check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };

    isMobile = checkMobile();
    churchVideo();
  });
}